import {
  useQueryClient,
  UseQueryResult,
  useMutation,
  useQuery,
  UseQueryOptions,
  UseMutationOptions,
  UseMutationResult,
  QueryClient,
  QueryClientProvider,
} from "@tanstack/react-query";
import { CapableClient } from "@capable-health/client";
import React, { createContext } from "react";

type CapableClientWithoutRequest = Omit<CapableClient, "request">;

type GetCapableClientHooks<Entities extends CapableClientWithoutRequest> = {
  -readonly [Entity in keyof Entities]: {
    // @ts-ignore
    [Method in Exclude<keyof CapableClient[Entity], "httpRequest">]: (
      // @ts-ignore
      parameters?: Parameters<CapableClient[Entity][Method]>[0],
      options?: Method extends `get${string}` ? UseQueryOptions : UseMutationOptions,
    ) => Method extends `get${string}` ? UseQueryResult : UseMutationResult;
  };
};

type CapableClientHooks = GetCapableClientHooks<CapableClientWithoutRequest>;

const queryClient = new QueryClient();

export const CapableClientHooksContext = createContext<CapableClientHooks>({} as CapableClientHooks);
export const CapableClientHooksProvider = ({ children, capableClient }: { children: React.ReactNode, capableClient: CapableClient }): JSX.Element => {
  const capableClientHooks: CapableClientHooks = {} as CapableClientHooks;

  const entityNames = Object.keys(capableClient) as Array<keyof CapableClient>;
  entityNames.forEach(( entityName) => {
    if (entityName === "request") {
      return;
    }

    if (!capableClientHooks[entityName]) {
      // @ts-ignore
      capableClientHooks[entityName] = {};
    }

    const classEntity = capableClient[entityName];
    for (const method in classEntity) {
      if (method === "httpRequest") {
        continue;
      }

      if (method.includes("get")) {
        const useCapableClientQuery = (parameters: any, options = {})  => useQuery(
          [entityName, JSON.stringify(parameters)],
          async () => {
            const entity = capableClient[entityName];
            if (method in entity) {
              // @ts-ignore
              const response = await entity[method](parameters);
              return response.data;
            }
          },
          options,
        );

        // @ts-ignore
        capableClientHooks[entityName][method] = useCapableClientQuery;
      } else {
        const useCapableClientMutation = (parameters: any, options = {})  => {
          const queryClient = useQueryClient();
        
          return useMutation(
            async () => {
              const entity = capableClient[entityName];
              if (method in entity) {
                // @ts-ignore
                return await entity[method](parameters);
              }
            },
            {
              onSuccess: () => {
                queryClient.invalidateQueries([entityName, JSON.stringify(parameters)]);
              },
              ...options,
            },
          );
        };

        // @ts-ignore
        capableClientHooks[entityName][method] = useCapableClientMutation;
      }
    }
  });

  return (
    <QueryClientProvider client={queryClient}>
      <CapableClientHooksContext.Provider value={capableClientHooks}>
        {children}
      </CapableClientHooksContext.Provider>  
    </QueryClientProvider>
  );
}
