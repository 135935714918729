import { ContentfulClientApi, createClient } from "contentful";
import { useMemo } from "react";

export const useContentfulClient = (client?: ContentfulClientApi) => {
  if (client) {
    return client;
  }

  if (
    !process.env.REACT_APP_CONTENTFUL_SPACE_ID ||
    !process.env.REACT_APP_CONTENTFUL_DELIVERY_TOKEN
  ) {
    const error =
      "Contentful environment variables are not defined. Cannot create contentful client.";
    throw new Error(error);
  }

  // eslint-disable-next-line react-hooks/rules-of-hooks
  const contentfulClient = useMemo(
    () =>
      createClient({
        space: process.env.REACT_APP_CONTENTFUL_SPACE_ID as string,
        accessToken: process.env.REACT_APP_CONTENTFUL_DELIVERY_TOKEN as string,
      }),
    []
  );

  return contentfulClient;
};
